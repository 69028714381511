<template>
<div style="text-align: left;">
    <div class="filters">
        <v-container style="align-self: center;">
            <v-row>
                <v-col class='buttonsContainer'>

                    <div class='buttonRow'>
                        <v-menu offset-y v-if="!filterByMeeting">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn depressed v-bind="attrs" v-on="on" style="align-self: center; text-transform:none;">
                                    <v-icon small>mdi-tune-vertical</v-icon>
                                    <str index="resources.filters.buttons.filter_by_meeting"/>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(meeting) in meetings" :key="`filter-meeting-${meeting.id}`" @click="filterByMeeting=meeting">
                                    <v-list-item-title>{{ meeting.title[language] }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-chip v-if="filterByMeeting" @click:close="filterByMeeting=null" close>
                            <v-icon small>mdi-filter-menu</v-icon>
                            {{filterByMeeting.title[language]}}
                        </v-chip>
                    </div>

                    <div class='buttonRow'>
                        <v-menu offset-y v-if="!filterByFileType">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn depressed v-bind="attrs" v-on="on" style="align-self: center; text-transform:none;">
                                    <v-icon small>mdi-tune-vertical</v-icon>
                                    <str index="resources.filters.buttons.filter_by_filetype"/>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(type) in fileTypes" :key="`filter-file-${type}`" @click="filterByFileType=type">
                                    <v-list-item-title>{{ type }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-chip v-if="filterByFileType" @click:close="filterByFileType=null" close>
                            <v-icon small>mdi-filter-menu</v-icon>
                            {{filterByFileType}}
                        </v-chip>
                    </div>
                </v-col>
                <!-- END buttonsContainer -->
            </v-row>
        </v-container>
    </div>

    <Resources :definition="resources"/>
</div>
</template>

<script>
import Resources from '@/components/Resources/Resources.vue'
import MeetingSystem from '@/components/Event/eventSystem.js'
export default {
  name: "ResourceView",
  created: async function(){
      this.meetingsAPI = new MeetingSystem(this)
      if(this.meetings==undefined){
          let meetings = await this.meetingsAPI.getMeetings()
          this.$store.commit('meetings',meetings)
      }
      await this.getTopics()
  },
  components: {
    Resources
  },
  data: function(){
    return {
      filterByMeeting: null,
      filterByFileType: null,
      topics: []
    }
  },
  methods: {
    getTopics: async function(){
      let self = this
      function allowTopic(topic){
          let username = self.$store.getters.user.username
          let restrictedTo = topic.restrictedTo
          let events = topic.events
          let meetingMap = self.meetingMap

          let myRoles = []
          for(let i=0; i<events.length; i++){
              let event_id = events[i]
              let meeting = meetingMap[event_id]

              if(meeting){
                  for(let role in meeting.roles){
                      let users = meeting.roles[role]
                      if(users.find((value)=>{return value==username})){
                          myRoles.push(role)
                      }
                  }
              }
          }

          let allowRole = false
          for(let i=0; i<myRoles.length; i++){
              let myRole = myRoles[i]
              if(restrictedTo.includes(myRole)){
                  allowRole = true
              }
          }

          return restrictedTo.length==0 || allowRole || self.$route.name=='Admin'
      }

      let response = await this.sendRequest('get','/api/discussionforum/topics')
      if(response.status==200){
        let topics = response.data
        for(let i=0; i<topics.length; i++){
          let topic = topics[i]
          if(allowTopic(topic)){
            this.topics.push(topic)
          }
        }

      }
    },
  },
  computed: {
    fileTypes: function(){
      return ['pdf','word','excel','image','link','video']
    },
    language: function(){
      return this.$store.getters.language
    },
    meetings: function(){
      return this.$store.getters.meetings
    },
    meetingMap: function(){
        let output = {}
        let meetings = this.meetings
        if(meetings){
            for(let i=0; i<meetings.length; i++){
                output[meetings[i].id] = meetings[i]
            }
        }
        return output
    },
    resources: function(){
      let meetings = this.meetings

      let output = []
      if(meetings){
        meetings = JSON.stringify(meetings)
        meetings = JSON.parse(meetings)
        for(let i=0; i<meetings.length; i++){
          let meeting = meetings[i]
          let resources = meeting.resources
          for(let ii=0; ii<resources.length; ii++){
            let resource = resources[ii]
            resource.eventId = meeting.id
            let filterId = this.filterByMeeting ? this.filterByMeeting.id : false
            if(filterId){
              if(filterId==resource.eventId){
                output.push(resource)
              }
            }else{
              output.push(resource)
            }
          }
        }
      }

      let topics = this.topics
      if(topics){
        topics = JSON.stringify(topics)
        topics = JSON.parse(topics)
        for(let i=0; i<topics.length; i++){
          let topic = topics[i]
          let resources = topic.resources
          for(let ii=0; ii<resources.length; ii++){
            let resource = resources[ii]
            console.log('topic resource',{
              resource,
              topic: topic.id
            })
            output.push(resource)
          }
        }
      }

      let filteredByType = []
      let types = {
        pdf: ['pdf'],
        word: ['doc','docx'],
        excel: ['xls','xlsx'],
        image: ['png','jpg','jpeg','bmp','gif'],
        video: ['mp4'],
      }
      for(let i=0; i<output.length; i++){
        let item = output[i]
        if(this.filterByFileType){
          if(this.filterByFileType=='link'){
            if(item.link){
              console.log({
                type: this.filterByFileType,
                item
              })
              filteredByType.push(item)
            }
          }else{
            let asset = item.asset
            if(asset){
              let extension = asset.Key.substr(asset.Key.lastIndexOf('.')+1,asset.Key.length).toLowerCase()
              if(types[this.filterByFileType] && types[this.filterByFileType].includes(extension)){
                filteredByType.push(item)
              }
            }
          }
        }else{
          filteredByType.push(item)
        }
      }
      return filteredByType
    }
  }
}
</script>

<style lang="scss" scoped>

    .filters{
        width: 100%;
        background-color: whitesmoke;
        box-shadow: inset 0px 0px 4px 0.5px rgba(0,0,0,0.2);
        padding: 0 12%;

        @media(max-width: 1199px){ // lg < 1200
            padding: 0 10%;
        }
        @media(max-width: 991px){ // md < 992
            padding: 0 8%;
        }
        @media(max-width: 767px){ // sm < 768
            padding: 0 6%;
        }
        @media(max-width: 575px){ // xs < 576
            padding: 0 4%;
        }

        .container {
            padding: 0; // remove default padding on .container
            width: 100%; // over-ride .container default
            max-width: none; // over-ride .container default

            .buttonsContainer {
                display: flex;

                @media(max-width: 575px){ // xs < 576
                    flex-flow: column;
                    align-items: center;
                }

                .buttonRow {
                    padding: 8px;

                    .v-btn {
                        min-width: 220px;
                        background-color: white;
                        box-shadow: 0px 0px 4px 0.5px rgba(0,0,0,0.2);

                        i {
                            margin-right: 8px;
                        }
                    }

                    .v-chip {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        min-width: 180px;

                        i {
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
</style>
